import React, { memo, useEffect } from 'react'
import Head from 'next/head'
import { Box, useTheme, BoxProps, Container, ContainerProps, Text } from '@thirstycamel/ui'

import MainFooter from '../../components/MainFooter'
import MainHeadNav from '../../components/MainHeadNav'
import MainSubNav from '../../components/MainSubNav'
import MainSidebarNav from '../../components/MainSidebarNav'
import PageUnderConstruction from '../../components/PageUnderConstruction'
import TitleBanner from '../../components/TitleBanner'
import { LastDrinksCountdown } from '../../components/LastDrinksCountdown'
import { useUser } from '../../hooks/useUser'
import { useRouter } from 'next/router'
import { useActions, useStore } from '../../store/hooks'
import { setCookie } from 'nookies'
import { sendMessageToApp } from '../../utils/sendMessageToApp'

export interface LayoutMainProps extends BoxProps {
  title?: string
  ogTitle?: string
  description?: string
  ogDescription?: string
  robots?: string
  ogImage?: string
  ogType?: string
  hideNav?: boolean
  hideFooter?: boolean
  containerSize?: ContainerProps['size']
  heading?: string
  subHeading?: string
  noContainer?: boolean
  isUnderConstruction?: boolean
  requireAuth?: boolean
  requireNoAuth?: boolean
  redirectTo?: string
  images?: string[]
  tags?: { property: string; content: string }[]
}

export function LayoutMain({
  title,
  ogTitle = title,
  description = 'Thirsty Camel bottleshops offer the very best in thirst satisfaction and drive thru convenience. Why not find your closest Thirsty Camel today and join the Hump Club for weekly discounts on your favourite beers, wine, spirits and more.',
  ogDescription = description,
  robots = 'index,follow',
  /* @TODO: ogImage */
  ogImage = 'https://springscentre.com.au/images/shops-images/large-images/Thirsty-Camel-full.jpg',
  ogType = 'website',
  children,
  hideNav,
  hideFooter,
  containerSize = 'lg',
  heading,
  subHeading,
  requireAuth,
  requireNoAuth,
  redirectTo,
  noContainer,
  isUnderConstruction,
  images,
  tags,
  ...restProps
}: LayoutMainProps) {
  const router = useRouter()
  const theme = useTheme()
  const { showModal } = useActions(actions => actions.modal)

  const _title = title ? `${title} | Thirsty Camel` : 'Thirsty Camel'
  const _themeColor = theme.colors.green['500']

  const showUnderConstruction = isUnderConstruction && process.env.NODE_ENV !== 'development'

  const { isLoggedIn, user } = useUser()
  const setRegion = useActions(store => store.location.setRegion)
  const { fetchProductLists } = useActions(actions => actions.navigation)
  const clearSelected = useActions(store => store.store.clearSelected)
  const { clearCartId, fetchCart } = useActions(store => store.cart)
  const location = useStore(s => s.location)
  const selectedStore = useStore(s => s.store.selectedStore)
  const region = selectedStore?.region || location.region || 'VIC'
  const currentPage = router.asPath

  useEffect(() => {
    if (requireAuth && !isLoggedIn) {
      router.push(`/login${redirectTo ? `?redirectTo=${redirectTo}` : ''}`)
    }

    if (requireNoAuth && isLoggedIn) {
      router.push('/account/offers')
    }
  }, [isLoggedIn, requireNoAuth, requireAuth])

  /* Testing tool - Press Ctrl + L to open a region switcher */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.onkeyup = function (e) {
        if (e.ctrlKey && e.code === 'KeyL') {
          const region = prompt('Which region would you like to switch to?')
          setRegion(region)
          clearSelected()
          fetchProductLists(null)
          clearCartId()
          fetchCart()
        }
      }
    }
  }, [typeof window])

  /* WA redirect */
  useEffect(() => {
    if (region === 'WA' && typeof window !== 'undefined') {
      showModal('WA')
    }
  }, [region, typeof window !== 'undefined'])

  useEffect(() => {
    /* Only show if homepage */
    if (region === 'SA' && typeof window !== 'undefined' && currentPage === '/') {
      showModal('SA')
    }

    /* Redirect to app link */
    if (region === 'SA' && typeof window !== 'undefined' && currentPage === '/page/camel-card') {
      window.location.href = 'https://sa.thirstycamelonline.com.au/t/pages/download-our-app'
    }
  })

  /* App functions */
  useEffect(() => {
    const pushToken = (window as any)?.PUSH_TOKEN

    if (pushToken) {
      setCookie(null, 'PUSH_TOKEN', pushToken, { path: '/' })
    }
  }, [typeof window !== 'undefined' && (window as any)?.PUSH_TOKEN])

  useEffect(() => {
    const isMobileApp = (window as any)?.IS_MOBILE_APP
    const platform = (window as any)?.APP_PLATFORM

    if (isMobileApp) {
      setCookie(null, 'IS_MOBILE_APP', isMobileApp, { path: '/' })
    }

    if (platform) {
      setCookie(null, 'APP_PLATFORM', platform, { path: '/' })
    }
  }, [])

  useEffect(() => {
    sendMessageToApp('CURRENT_USER_UPDATE', user?.id)
  }, [user?.id])

  return (
    <Box {...restProps}>
      <Head>
        <title>{_title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content={robots} />
        <meta name="theme-color" content={_themeColor} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="twitter:card" content="summary" />

        <meta property="og:title" content={ogTitle || _title} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:type" content={ogType} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content="https://thirstycamel.com.au" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="facebook-domain-verification" content="s8npamjx7rgpxcann8vdbm7t6a0i66" />

        <meta
          name="google-site-verification"
          content="gMWjoYrLOWAWk5yMzSoLCrLx4Qmhn-7-uw99KRCLfZA"
        />
        <meta
          name="google-site-verification"
          content="gyZ4_PMHKDCdpvyjNhttbj8e06AXETEproPpHCsfzQ0"
        />
        <meta
          name="google-site-verification"
          content="PzUT4GucKeeNgYm3rL9EnbdseHr3CkcdK1-DidiVNpU"
        />

        {images?.map(i => (
          <>
            <link rel="preload" href={i} as="image" type="image/webp" />
          </>
        ))}

        {tags?.map(t => (
          <meta property={t.property} content={t.content} />
        ))}
      </Head>

      <MainSidebarNav />

      {/* pt = MainSubNav + MainHeadNav height */}
      <Box pt={hideNav ? null : [48 + 64, 64 + 86]}>
        {!hideNav ? (
          <Box position="fixed" top={0} left={0} right={0} zIndex={theme.zIndices.docked}>
            <MainHeadNav />
            <MainSubNav />
          </Box>
        ) : null}

        <Box
          display="flex"
          minHeight={[`calc(100vh - 112px)`, `calc(100vh - 150px)`]}
          flexDirection="column"
        >
          {showUnderConstruction ? (
            <PageUnderConstruction />
          ) : (
            <>
              {heading && <TitleBanner title={heading} containerSize={containerSize} />}

              {!noContainer ? (
                <Container as="main" size={containerSize}>
                  {children}
                </Container>
              ) : (
                children
              )}
            </>
          )}

          {!hideFooter ? <MainFooter marginTop="auto" style={router?.asPath?.startsWith("/games/") ? { scrollSnapAlign: "end" } : null}/> : null}
        </Box>
      </Box>
    </Box>
  )
}

export default memo(LayoutMain)
