import React, { useRef, useEffect, useState } from 'react'
import {
  Box,
  useTheme,
  CloseButton,
  Stack,
  useRouteChange,
  Flex,
  usePrevious,
  Heading,
  RemoveScroll,
  BackButton,
} from '@thirstycamel/ui'

import { useStore, useActions } from '../../store/hooks'
import MainSidebarNavItem, { MainSidebarButtonItem } from '../MainSidebarNavItem'
import { getSidebarItems } from '../../layout/LayoutAccount'
import { useUser } from '../../hooks/useUser'
import { useGamesStatus } from '../../hooks/useGamesStatus'

const MainSidebarNavHeading = props => (
  <Heading textTransform="uppercase" fontSize="xs" color="blackAlpha.700" {...props} />
)

export const MainSidebarNav = () => {
  const theme = useTheme()
  const { user, isGamesTestUser } = useUser()
  const { isFetching, isActive } = useGamesStatus()

  const { isVisible } = useStore(store => store.sidebar)
  const { hideSidebar } = useActions(store => store.sidebar)
  const { fetchProductLists } = useActions(actions => actions.navigation)
  const isDelivery = useStore(store => store.cart?.cart?.type === 'TAH')

  // const [selectedItem, setSelectedItem] = useState<any>(null)
  const [categoryIndex, setCategoryIndex] = useState<number | null>(null)

  const wasVisible = usePrevious(isVisible)

  const closeButtonRef = useRef(null)

  const location = useStore(s => s.location)
  const selectedStore = useStore(s => s.store.selectedStore)
  const region = selectedStore?.region || location.region || 'VIC'

  useEffect(() => {
    if (!wasVisible && isVisible) {
      /* If the sidebar was just opened, focus on the close button. */
      closeButtonRef.current?.focus()
    } else if (wasVisible && !isVisible) {
      /* If the sidebar was just closed, blur the close button so the user can't be focused on it anymore. */
      closeButtonRef.current?.blur()
    }
  }, [isVisible])

  useEffect(() => {
    fetchProductLists(region)
  }, [region])

  const handleClose = () => {
    hideSidebar()
    setCategoryIndex(null)
  }

  const handleRouteChange = () => {
    if (isVisible) {
      hideSidebar()
    }
  }

  const handleClick = (index: number) => {
    console.log('handleClick', index)
    setCategoryIndex(index)
    closeButtonRef.current?.scrollIntoView()
  }

  const handleBack = () => {
    setCategoryIndex(null)
  }

  useRouteChange(handleRouteChange)

  const rawCategoryData = useStore(store => store.navigation.categories)
  const categories = rawCategoryData.map(r => ({
    label: r.name,
    slug: r.slug,
    order: r.order,
    types: r.categories.map(r => ({ label: r.name, slug: r.slug, type: 'category' })),
    type: 'category',
  }))

  const humpClubItems = user && getSidebarItems(user)

  const rawListData = useStore(store => store.navigation.productLists)
  let productLists = []
  if (rawListData) {
    // console.log("rawListData:",rawListData?.length);

    productLists = rawListData.map(r => ({
      label: r.title,
      slug: r.slug,
      types: [],
      order: r.order,
      type: 'productlist',
    }))
  } else {
    console.log('no product lists data')
  }

  let filteredData = null
  let selected = null

  let combinedData = [...categories, ...productLists].sort(
    (a, b) => (a.order != null ? a.order : 999) - (b.order != null ? b.order : 999),
  )

  if (categoryIndex != null) {
    console.log(combinedData?.[categoryIndex])
    filteredData = combinedData?.[categoryIndex]?.types
    selected = combinedData?.[categoryIndex]
  } else {
    filteredData = combinedData
  }

  return (
    <RemoveScroll enabled={isVisible} removeScrollBar={false}>
      <Box
        position="fixed"
        left={0}
        right={0}
        top={0}
        bottom={0}
        transform={isVisible ? `translateZ(0)` : 'translateX(100%) translateZ(0)'}
        transition="300ms ease transform"
        bg="white"
        boxShadow="lg"
        overflowY="auto"
        zIndex={theme.zIndices.overlay}
        aria-hidden={!isVisible}
      >
        <Flex justify={categoryIndex != null ? 'space-between' : 'flex-end'} p={4}>
          {categoryIndex != null ? <BackButton onClick={handleBack} /> : <Box width="32px" />}
          <CloseButton onClick={handleClose} ref={closeButtonRef} />
        </Flex>

        <Stack spacing={4}>
          <Stack spacing={3}>
            <MainSidebarNavHeading mx={6}>
              Browse {categoryIndex != null ? selected?.label : ''} products
            </MainSidebarNavHeading>

            {categoryIndex == null && !isDelivery && (
              <MainSidebarNavItem
                label="Specials"
                href="/[group]"
                hrefAs="/specials"
                iconRight="chevron-right"
              />
            )}
            {categoryIndex == null && ['VIC', 'TAS', 'NSW'].includes(region) && (
              <MainSidebarNavItem
                label="Offers"
                href="/[group]"
                hrefAs="/offers"
                iconRight="chevron-right"
              />
            )}
            {categoryIndex == null && !isDelivery && (
              <MainSidebarNavItem
                label="Everyday Value"
                href="/[group]"
                hrefAs="/everyday-value"
                iconRight="chevron-right"
              />
            )}

            {categoryIndex != null && selected && (
              <>
                <MainSidebarNavItem
                  label={`${selected.label} Specials`}
                  key={selected.slug}
                  textTransform="uppercase"
                  href="/[group]?specials=true"
                  onClick={handleClose}
                  iconRight="chevron-right"
                  hrefAs={`/${selected.slug}?specials=true`}
                />

                <MainSidebarNavItem
                  label={`All ${selected.label}`}
                  key={selected.slug}
                  textTransform="uppercase"
                  href="/[group]"
                  onClick={handleClose}
                  iconRight="chevron-right"
                  hrefAs={`/${selected.slug}`}
                />

                <Box h="1px" bg="gray.300" my={2} mx={6} flexShrink={0} />
              </>
            )}

            {filteredData.map((item, index) => {
              if (item.type === 'category') {
                return categoryIndex != null ? (
                  <MainSidebarNavItem
                    key={`${selected.slug}_${item.slug}`}
                    label={item.label}
                    href="/[group]/[category]"
                    hrefAs={`/${selected.slug}/${item.slug}`}
                    iconRight="chevron-right"
                    onClick={handleClose}
                  />
                ) : (
                  <MainSidebarButtonItem
                    key={item.slug}
                    label={item.label}
                    iconRight="chevron-right"
                    onClick={() => handleClick(index)}
                  />
                )
              }

              if (item.type === 'productlist') {
                return (
                  <MainSidebarNavItem
                    label={item.label}
                    key={item.slug}
                    textTransform="uppercase"
                    href="/[group]"
                    onClick={handleClose}
                    iconRight="chevron-right"
                    hrefAs={`/${item.slug}`}
                  />
                )
              }
            })}

            {categoryIndex == null && (
              <MainSidebarNavItem
                label="Bundles"
                textTransform="uppercase"
                href="/[group]"
                onClick={handleClose}
                iconRight="chevron-right"
                hrefAs={`/bundles`}
              />
            )}
          </Stack>

          <Stack spacing={6}>
            <Box h="1px" bg="gray.300" my={2} mx={6} flexShrink={0} />

            <Stack spacing={3}>
              <MainSidebarNavHeading mx={6}>Quick links</MainSidebarNavHeading>

              <Stack>
                <MainSidebarNavItem label="Cart" iconLeft="cart" href="/cart" />
                <MainSidebarNavItem label="Competitions" iconLeft="trophy" href="/competitions" />
                {(!isFetching &&
                  isActive &&
                  isGamesTestUser) && (
                  <MainSidebarNavItem label="Games" iconLeft="trophy" href="/games" />
                )}
                
                <MainSidebarNavItem label="Hump Club" iconLeft="star" href="/account" />
              </Stack>
            </Stack>
          </Stack>

          {user && (
            <Stack spacing={6}>
              <Box h="1px" bg="gray.300" mx={5} flexShrink={0} />

              <Stack spacing={3}>
                <MainSidebarNavHeading mx={6}>Hump Club</MainSidebarNavHeading>

                <Stack>
                  {humpClubItems?.map(item => (
                    <MainSidebarNavItem
                      key={item.text}
                      label={item.text}
                      href={item.href}
                      iconRight="chevron-right"
                      onClick={handleClose}
                    />
                  ))}
                </Stack>
              </Stack>
            </Stack>
          )}

          <Box h={20} flexShrink={0} />
        </Stack>
      </Box>
    </RemoveScroll>
  )
}

export default MainSidebarNav
