import React, { memo } from 'react'
import { Flex, Container, Box, LinkButton, Badge, Stack, IconButton } from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import MainSubNavStorePicker from '../MainSubNavStorePicker'
import MainSubNavItem from '../MainSubNavItem'
import MainSubNavCategories from '../MainSubNavCategories'
import MainSubNavHumpClub from '../MainSubNavHumpClub'
import MainSubNavCart from '../MainSubNavCart'
import Link from 'next/link'
import { useUser } from '../../hooks/useUser'

export interface MainSubNavProps {}

export const MainSubNav = ({ ...restProps }: MainSubNavProps) => {
  const showSidebar = useActions(store => store.sidebar.showSidebar)

  const isDelivery = useStore(store => store.cart?.cart?.type === 'TAH')
  const cart = useStore(store => store.cart.cart)

  const cartItemsBadge = !cart ? null : (
    <Badge
      isRound
      variantColor="pink"
      pos="absolute"
      top="calc(50% + 0.5rem)"
      right={-2}
      transform="translateY(-50%)"
      boxShadow="md"
      pointerEvents="none"
    >
      {cart?.cartItems.length}
    </Badge>
  )

  return (
    <Flex height={['48px', '64px']} bg="white" align="center" shadow="md" {...restProps}>
      <Container px={[3, 3, 3, 8]} size="xl" h="100%">
        <Flex align="center" justify="space-between" h="100%">
          {/* All screens */}
          <Box flex="1 0 0" h="100%">
            <MainSubNavStorePicker />
          </Box>

          {/* Mobile, tablet */}
          <Stack
            isInline
            flex="1 0 0"
            h="100%"
            justify="flex-end"
            align="center"
            display={['flex', , 'none']}
            spacing={1}
          >
            {/* Menu button */}
            <IconButton
              icon="menu"
              fontSize="3xl"
              color="pink.500"
              variant="ghost"
              isRound
              onClick={() => showSidebar()}
            />

            {/* Bottleshop Finder button */}
            <Link href="/store-locator" passHref>
              <IconButton
                as="a"
                icon="place"
                fontSize="2xl"
                color="pink.500"
                variant="ghost"
                isRound
              />
            </Link>

            {/* Cart button */}
            <Link href="/cart" passHref>
              <IconButton icon="cart" fontSize="2xl" color="pink.500" variant="ghost" isRound>
                {cartItemsBadge}
              </IconButton>
            </Link>
          </Stack>

          {/* Desktop */}
          <Flex flex="1 0 0" h="100%" justify="center" display={['none', , 'flex']}>
            {!isDelivery && (
              <LinkButton
                href="/[group]"
                hrefAs="/specials"
                variant="unstyled"
                h="100%"
                color="pink.500"
                _hover={{ color: 'pink.600' }}
              >
                <MainSubNavItem label="SPECIALS" px={[2,2, 2, 4]} />
              </LinkButton>
            )}

            {!isDelivery && (
              <LinkButton
                href="/[group]"
                hrefAs="/everyday-value"
                variant="unstyled"
                h="100%"
                color="pink.500"
                _hover={{ color: 'pink.600' }}
              >
                <MainSubNavItem label="EVERYDAY VALUE" px={[2,2, 2, 4]} />
              </LinkButton>
            )}

            <MainSubNavCategories />

            <LinkButton
              href="/competitions"
              variant="unstyled"
              h="100%"
              color="pink.500"
              _hover={{ color: 'pink.600' }}
            >
              <MainSubNavItem label="COMPETITIONS" px={[2,2, 2, 4]} />
            </LinkButton>

            <MainSubNavHumpClub />           
          </Flex>

          {/* Tablet */}
          <Stack
            flex="1 0 0"
            justify="flex-end"
            align="center"
            h="100%"
            display={['none', , 'flex', 'none']}
            isInline
          >
            <Link href="/cart" passHref>
              <IconButton icon="cart" fontSize="2xl" color="pink.500" variant="ghost" isRound>
                {cartItemsBadge}
              </IconButton>
            </Link>

            <Link href="/store-locator" passHref>
              <IconButton
                as="a"
                icon="place"
                fontSize="2xl"
                color="pink.500"
                variant="ghost"
                isRound
              />
            </Link>
          </Stack>

          {/* Large Desktop */}
          <Flex flex="1 0 0" justify="flex-end" h="100%" display={['none', , , 'flex']}>
            <Flex display={['none', , 'flex']}>
              <MainSubNavCart>
                <MainSubNavItem icon="cart" label="Cart" px={4} bg="white" />
              </MainSubNavCart>

              <LinkButton
                href="/store-locator"
                variant="unstyled"
                h="100%"
                color="pink.500"
                _hover={{ color: 'pink.600' }}
              >
                <MainSubNavItem icon="place" label="Bottleshop Finder" px={4} />
              </LinkButton>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}

export default memo(MainSubNav)
