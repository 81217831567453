import React from 'react'
import { Popout, PopoutContent, PopoutTrigger, Button, Stack, LinkButton} from '@thirstycamel/ui'

import MainSubNavItem from '../MainSubNavItem'
import CategoriesPopout from '../CategoriesPopout'
import { useUser } from '../../hooks/useUser'
import { useGamesStatus } from '../../hooks/useGamesStatus'
import { useStore } from '../../store/hooks'

export const MainSubNavHumpClub = () => {
  const location = useStore(s => s.location)
  const selectedStore = useStore(s => s.store.selectedStore)
  const region = selectedStore?.region || location.region || 'VIC'
  const { isGamesTestUser } = useUser()
  const { isFetching, isActive } = useGamesStatus()

  const heading = <MainSubNavItem label="HUMP CLUB" iconRight="chevron-down" spacing={0} px={[2,2, 2, 4]} />

  return (
    <Popout placement="bottom" strategy="fixed">
      <PopoutTrigger>
        <Button variant="unstyled" h="100%" color="pink.500" _hover={{ color: 'pink.600' }}>
          {heading}
        </Button>
      </PopoutTrigger>

      <PopoutContent>
        <Stack
          bg="gray.50"
          boxShadow="lg"
          px={5}
          py={5}
          pb={8}
        >
          {['VIC', 'NSWBR', 'TAS', 'QLD'].includes(region) && (
              <LinkButton
                href="/offers"
                hrefAs="/offers"
                variant="unstyled"
                h="100%"
                color="pink.500"
                _hover={{ color: 'pink.600' }}
              >
                <MainSubNavItem label="OFFERS" px={[2, 2, 2, 4]} py={3}/>
              </LinkButton>
            )}

            {(
              !isFetching &&
              isActive &&
              isGamesTestUser
              ) && (
              <LinkButton
                href="/games"
                variant="unstyled"
                h="100%"
                color="pink.500"
                _hover={{ color: 'pink.600' }}
              >
                <MainSubNavItem label="GAMES" px={[2,2, 2, 4]} />
              </LinkButton>
            )}

        </Stack>
      </PopoutContent>
    </Popout>
  )
}

export default MainSubNavHumpClub
